<template lang="pug">
	main#app
		article#page
			router-view
		Footer
</template>

<script>
import Footer from '@components/Footer/Footer'

export default {
	name: 'template-app',
	metaInfo() {
		return {
			title: 'Mind Service',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	components: {
		Footer
	},
}
</script>

<style lang="stylus" scoped src="./App.styl"></style>
