/**
 * Sitemap
 * 	Referência: https://github.com/cheap-glitch/vue-cli-plugin-sitemap#readme
 * 	Adicionar o seguinte na rota em que precise alterar manualmente os valores do sitemap, com os respectivos valores:
		meta: {
			sitemap: {
				lastmod: new Date(),
				priority: 0.8,
				changefreq: 'daily',
			}
		}
 */

let routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Home" */ '@views/Home/Home'),
	},
	{
		path: '/servicos',
		name: 'Servicos',
		component: () => import(/* webpackChunkName: "Servicos" */ '@views/Servicos/Servicos'),
	},
	{
		path: '/equipe',
		name: 'Equipe',
		component: () => import(/* webpackChunkName: "Equipe" */ '@views/Equipe/Equipe'),
	},
	{
		path: '/contato',
		name: 'Contato',
		component: () => import(/* webpackChunkName: "Contato" */ '@views/Contato/Contato'),
	},
	{
		path: '/cases',
		name: 'Cases',
		component: () => import(/* webpackChunkName: "Cases" */ '@views/Cases/Cases'),
	},
	{
		path: '/cases/:slug',
		name: 'Case',
		component: () => import(/* webpackChunkName: "Case" */ '@views/Case/Case'),
		meta: {
			sitemap: {
				slugs: [
					'almha',
					'faculade-inspirar'
				]
			}
		}
	},
]

export default routes
