<template lang="pug">
	footer#footer
		.wrapper
			h2
				|L
				span._1100 e
				|t`s ch
				span._1100 a
				|ng
				span._1300 e
				br
				|th
				span._1100 e
				|  w
				span._1900 o
				| rld`s m
				span._1100 i
				| nd?
			ul.icons
				li
					SvgIcon(data="@svgs/icon_01.svg", original)._01
				li
					SvgIcon(data="@svgs/icon_02.svg", original)._02
				li
					SvgIcon(data="@svgs/icon_03.svg", original)._03
			ul.nav
				li.item
					router-link(:to="{name: 'Home'}")
						span MIND
				li.item
					router-link(:to="{name: 'Servicos'}")
						span SERVIÇOS
				li.item
					router-link(:to="{name: 'Cases'}")
						span PROJETOS
				li.item
					a(href="#")
						span CLIENTES
				li.item
					router-link(:to="{name: 'Equipe'}")
						span EQUIPE
				li.item
					router-link(:to="{name: 'Contato'}")
						span CONTATO
			ul.redes
				li.rede
					a(href="https://www.instagram.com/mindservice.co/", target="_blank")
						FontAwesomeIcon(:icon="icons.faInstagram")
				li.rede
					a(href="https://www.linkedin.com/company/mindservice/", target="_blank")
						FontAwesomeIcon(:icon="icons.faLinkedin")
</template>

<script>
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default {
	name: 'component-footer',
	data(){
		return {
			icons: {
				faInstagram,
				faLinkedin
			}
		}
	},
}
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>
