import '@stylus/main.styl'

import router from '@/router'
import store from '@/store'
import { App } from '@templates'
import Axios from 'axios'
import Vue from 'vue'
// https://fontawesome.com/docs/web/use-with/vue/dig-deeper
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@yzfe/svgicon/lib/svgicon.css'
import { VueSvgIcon } from '@yzfe/vue-svgicon'
import MarqueeText from 'vue-marquee-text-component'
import VueMeta from 'vue-meta'
import ScrollMagic from 'vue-scrollmagic'

Vue.config.productionTip = false

Vue.use(ScrollMagic, {
  addIndicators: false
})

Vue.component('MarqueeText', MarqueeText)
Vue.component('SvgIcon', VueSvgIcon)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.use(VueMeta)

Vue.prototype.$axios = Axios.create({
  baseURL: `${process.env.VUE_APP_MAILER_URL}`
})

Vue.component('SvgIcon', VueSvgIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
